import React, { useEffect } from "react"
import { Link, Route, Redirect } from "react-router-dom"
// import * as Const from "./../../../constants/Const"
import * as Fc from "./../../../utils/Functions"
import * as Const from "./../../../constants/Const"
import * as type from './../../../constants/actionType'

//screens
import ChangePassword from "../../Login/_ChangePassword"
import Browse from "../../OperatorAdmin/Browse"
import SectionQuizTest from "./../../OperatorAdmin/Browse/SearchSectionQuizTest"
import LayoutSearch from "./../../OperatorAdmin/Main/LayoutSearch"
import Workbook from "../../OperatorAdmin/Workbook"

import Lession from "../../OperatorAdmin/Workbook/Lession"
import ButtonGoBack from '../../../components/ButtonGoBack'
import Loading from '../../../components/Loading'
import { useSelector, useDispatch } from 'react-redux'
import HeaderDesktop from "../../../components/HeaderDesktop/HeaderDesktop"

import {
  isDesktop,
  isTablet, isIPad13,
  isMobile
} from "react-device-detect";

function LayoutComponent(props) {
  let dispatch = useDispatch()
  const {
    hideMenu,
    showMenu,
    matchUrl,
    logOut,
    operatorInfo,
    openPopup,
    setOpenPopup,
    socket,
    message,

    setMessage,
    messageList,
    setMessageList,
    role,
    listClass,
    ClassId,
    setClassId, ListStudent,
    ListTeacher,
    setSelectedId,
    selectedId,

    room,
    tabChat, setTabChat,
    userType, setUserType,
    userName, setUserName,
    primaryTeacherId,
    getListNotif, scrollToBottom, messageEndRef,
    getListMessageInGroup, GetChatMessages,
    getDetailClass,
    setPageIndex,
    onScrollTop,
    pageIndex
  } = props

  let user = useSelector(state => state.ws.userLogin)
  let countNotif = useSelector(state => state.ws.countNotif)
  let searchParams = useSelector(state => state.ws.params)

  const sendMessage = async () => {

    if (message === "") {
      return
    }
    let toName = ""
    let to = ""
    let toRoom = room
    let toRole = ""

    if (selectedId === "" && tabChat === 'contactAdmin') {
      toName = ""
      to = `${Fc.getOperator()}|${Fc.getUserId()}`
      toRoom = Fc.getOperator()
      toRole = userType
    } else if (selectedId !== "" && selectedId !== "Teacher/Student") {
      toName = userName
      to = selectedId
      toRole = userType
    } else {
      toName = ClassId
      to = room
      toRole = "CLASS"
    }

    let messageContent = {
      room: toRoom,
      Token: Fc.getToken(),
      content: {
        From: Fc.getUserId(),
        To: to,
        Type: (selectedId !== "" && selectedId !== "Teacher/Student") || tabChat === 'contactAdmin' ? "normal" : "groupchat",
        Body: message,
        FromRole: Fc.getRole(),
        ToRole: toRole,
        FromName: user.FirstName + " " + user.LastName,
        // ToName: selectedId !== "" ? userName : ClassId,
        ToName: toName,
        ClassId: ClassId,
        CreatedDate: new Date()
      },
    };

    await socket.emit("send_message", messageContent);

    setMessageList([...messageList, messageContent.content]);
    setMessage("");
  };

  if (Fc.getToken() === null) {
    return (<Redirect to='/' />)
  } else
    return (
      <>
        <Loading />
        <b className="screen-overlay"></b>
        <aside className="offcanvas" id="my_offcanvas1">
          <header className="mt-5a">
            <button className="btn btn-close">
              <i className="fas fa-times fa-2x text-white"></i>{" "}
            </button>
          </header>

          <nav className="list-group list-group-flush">
            <Link
              onClick={hideMenu}
              to={`${matchUrl.url}`}
              className="list-group-item bg-transparent font-Paytone font18 border-0 mobile-d-none"
            >
              Browse
            </Link>
            <Link
              onClick={hideMenu}
              to={`${matchUrl.url}/Classes`}
              className="list-group-item bg-transparent font-Paytone font18 border-0"
            >
              Classroom
            </Link>
            <Link
              onClick={hideMenu}
              to={`${matchUrl.url}/ChangePassword`}
              className="list-group-item bg-transparent font-Paytone font18 border-0"
            >
              Change Password
            </Link>
            <Link
              to="#"
              onClick={logOut}
              className="list-group-item bg-transparent font-Paytone font18 border-0"
            >
              <span className="text-danger">Log Out</span>
            </Link>
          </nav>
        </aside>

        {/* Modal Search */}
        <div className="modal fade" id="myModal">
          <LayoutSearch matchUrl={matchUrl} {...props} />
        </div>
        {/* End search */}

        {(isDesktop && !isMobile)
          && (<HeaderDesktop
              role={role}
              hideMenu={hideMenu}
              matchUrl={matchUrl}
              operatorInfo={operatorInfo}
              logOut={logOut}
            />)
        }

        {(!isDesktop || isIPad13) && <nav className="header fixed-top p-3 z-5 drop-shadow">
          <div className="row align-items-center">
            <div className="col-auto">
              <Link to="#"
                onClick={showMenu}
                
                >
                <i className="fas fa-bars fa-2x"></i>
              </Link>
            </div>

            <div className="mx-auto">
              <h3 className="font-Paytone">
                {
                  !(operatorInfo.BusinessName || operatorInfo.FullName)
                    ? ''
                    : (operatorInfo.BusinessName || operatorInfo.FullName)
                }
              </h3>
            </div>

            <div className="col-auto">
              {
                matchUrl.isExact
                  ? (<Link className="" to="#" data-toggle="modal" data-target="#myModal"> <i className="fas fa-search fa-2x"></i> </Link>)
                  : (
                    <>
                      <Route path={`${matchUrl.path}/Classes`} >
                        <ButtonGoBack handleClick={() => { hideMenu(); props.history.push(`${matchUrl.url}`); }} />
                      </Route>

                      <Route path={`${matchUrl.path}/Student/:studentId/:classId`} exact>
                        <ButtonGoBack
                          handleClick={() => {
                            hideMenu()
                            let url = window.location.href
                            while (url.endsWith("/")) {
                              url = url.substring(0, url.length - 1)
                            }
                            let classId = url.split("/").reverse()[0]
                            props.history.push(`${matchUrl.url}/Classes/${classId}`)
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Student/:studentId/:classId/Edit/:type/:assignId/:pageIndex`}>
                        <ButtonGoBack
                          handleClick={() => {
                            dispatch({ type: type.SAVE_PARAMS_SEARCH, payload: null })
                            dispatch({ type: type.SAVE_HEADER_EDIT_ACTIVE, payload: 'Exercise' })
                            dispatch({ type: type.SAVE_PAGEINDEX_WS, payload: null })
                            dispatch({ type: type.SAVE_PAGEINDEX_NOTE, payload: 0 })
                            
                            let url = window.location.href
                            let eIndex = url.toLowerCase().indexOf("/edit/")
                            url = url.substring(0, eIndex)
                            let sIndex = url.toLowerCase().indexOf("/student/")
                            url = url.substring(sIndex)
                            props.history.push(`${matchUrl.url}${url}`)
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Search`} exact>
                        <ButtonGoBack handleClick={() => { hideMenu(); props.history.push(`${matchUrl.url}`); }} />
                      </Route>

                      <Route path={`${matchUrl.path}/Workbook/:workbookId`} exact>
                        <ButtonGoBack handleClick={() => {
                          hideMenu();
                          dispatch({ type: type.SELECTWS, payload: [] })
                          props.history.push(`${matchUrl.url}`);
                        }} />
                      </Route>

                      <Route path={`${matchUrl.path}/Workbook/:workbookId/Lession/:lessionId`}>
                        <ButtonGoBack
                          handleClick={() => {
                            hideMenu()
                            if (!searchParams) {
                              let url = window.location.href
                              let eIndex = url.toLowerCase().indexOf("/lession/")
                              url = url.substring(0, eIndex)
                              let sIndex = url.toLowerCase().indexOf("/workbook/")
                              url = url.substring(sIndex)
                              props.history.push(`${matchUrl.url}${url}`)
                              return
                            }

                            // 
                            // if (matchUrl.params.id) {
                            //   if (role === Const.Role.VENDOR) {
                            //     props.history.push(`/Vendor/ManageClient/${operatorId}/${searchParams}`)
                            //   } 
                            // }
                            if (role === Const.Role.OPERATOR) {
                              props.history.push(`/operator/${searchParams}`)
                            } else {
                              props.history.push(`/Teacher/${searchParams}`)
                            }
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/ChangePassword`} exact >
                        <ButtonGoBack handleClick={() => { hideMenu(); props.history.push(`${matchUrl.path}`); }} />
                      </Route>
                    </>
                  )}

            </div>
          </div>
        </nav>}

        <main>
          <Route path={`${matchUrl.path}`} exact component={Browse} />
          <Route path={`${matchUrl.path}/Search`} exact component={SectionQuizTest} />
          <Route path={`${matchUrl.path}/ChangePassword`}>
            <div className="bg-xanh"></div>
            {/* <div className="row">
              <div className="col-6 offset-3" style={{top: "150px"}}>
                <div className="row align-items-center">
                  <div className="mx-auto">
                    <h3 className="font-Paytone">Change Password</h3>
                  </div>
                </div>
              </div>
            </div> */}
            <ChangePassword {...props} />
          </Route>
          <Route path={`${matchUrl.path}/Workbook/:workbookId`} exact component={Workbook} />
          <Route path={`${matchUrl.path}/Workbook/:workbookId/Lession/:lessionId`} component={Lession} />

        
        </main>
      </>
    )
}

export default LayoutComponent
