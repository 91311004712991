// export const Domain = "http://172.16.1.132:8084"
export const Domain = "https://admin.epentutor.com"

//account
// export const UrlAccount = "http://172.16.1.173:8090/epenapi/v1/account-services"
export const UrlAccount = "https://auth.epentutor.com:8443/ePenDataApi-0.0.1-SNAPSHOT"

//websocket
// export const WebSocketServer = "ws://172.16.1.173:8067/" //server socket noi bo
// export const WebSocketServer = "ws://172.16.0.108:8067/" //huynh local computer
export const WebSocketServer = "wss://epentutor.com:2053/"

//Chat
// export const CONNECTION_PORT = "172.16.1.173:3002/";
export const CONNECTION_PORT = "https://admin.epentutor.com:2083/";

export const Login = "/account/session"
export const ChangePass = "/account/session/pass"
export const ForgetPassword = "/account/send/mail"
export const ChangePassByToken = "/account/forget/pass"
export const GetAccountInfo = "/api/Account/GetAccountInfo"

//admin
export const GetClients = "/api/Operator/Search"
export const CreateClient = "/api/Operator/Create"
export const UpdateClient = "/api/Operator/Update"
export const GetDetailClient = "/api/Operator/Detail"
export const DeleteClient = "/api/Operator/Delete"

//users
export const ListUsers = "/api/Class/SearchStudentAndTeacher"
export const EditTeacher = "/api/Teacher/Update"
export const EditStudent = "/api/Student/Update"
export const InsertStudents = "/api/Student/Create"
export const InsertTeachers = "/api/Teacher/Create"
export const GetAccountId = "/user/accountId"
export const DeleteTeacher = "/api/Teacher/Delete"
export const DeleteStudent = "/api/Student/Delete"
export const GetDetailStudent = "/api/Student/Details"
export const GetDetailTeacher = "/api/Teacher/Details"
export const ListTeachers = "/api/Teacher/Search"


//Class
export const CreateClass = "/api/Class/Create"
export const ListClass = "/api/Class/Search"
export const DetailClass = "/api/Class/Details"
export const UpdateClass = "/api/Class/Update";
export const UpdateStudentSolution = "/api/Class/UpdateSolutionStatusForStudent";
export const DeleteClass = "/api/Class/Delete";
export const GetMessageDateList = "/api/Class/GetMessageDateList";
export const GetMessageByDate = "/api/Class/GetMessageByDate";
export const SendMessageToClass = "/api/Class/SendMessageToClass";
export const GetClassByPrimaryTeacher = "/api/Class/GetClassByPrimaryTeacherToken";
export const GetClassDetail = "/api/Class/Details";

//Setting
export const GetAccountPayment = "/api/Setting/GetAccountPayment"
export const GetBannerPayment = "/api/Setting/GetBannerPayment"
export const UpdateAccountPayment = "/api/Setting/UpdateAccountPayment"
export const UpdateBannerPayment = "/api/Setting/UpdateBannerPayment"
export const UpdateBannerByOperator = "/api/Operator/GetBannerByOperatorId"

// Region
export const GetListCountry = "/api/Region/GetListCountry"
export const GetListRegionByParent = "/api/Region/GetListRegionByParent"

/**
 * WorkBook
 */
export const GetAllCategories = "/api/Config/GetAllCategories"
export const GetAllLevels = "/api/Config/GetAllLevels"
export const GetAllSubjects = "/api/Config/GetAllSubjects"

//WorkBook search
export const WorkBookSearch = "/api/Workbook/Search"
export const WorkBookSearchWithKeywords = "/api/Workbook/SearchWithKeyword"
export const GetWorkSheetsByDocumentId = "/api/Workbook/GetWorkSheetsByDocumentId"
export const WorkBookSearchWithTab = "/api/Workbook/SearchWithKeywordWithTab"
export const GetWorkSheetsBySectionId = "/api/Workbook/GetWorkSheetsBySectionId"
export const GetWorkbookDetail = "/api/Workbook/Details"

//Document
export const UploadDocument = "/api/Document/Upload"
export const UploadDocumentForStudent = "/api/Document/UploadForStudent"
export const SearchDocument = "/api/Document/Search"
export const SearchDocumentForStudent = "/api/Document/SearchForStudent"
export const DeleteDocument = "/api/Document/Delete"
export const GetWorksheetByDocumentAndIndex = "/api/Document/GetWorksheetByDocumentAndIndex"
export const UpdateDrawOnWorksheet = "/api/Document/UpdateDrawOnWorksheet"

//Asignment
export const SendWorksheetsToStudents = "/api/Assignment/SendToStudents"

//Banner
export const UploadBanner = "/api/Operator/UploadBanner"

// Student
export const StudentGetMessageByDate = "/api/Student/GetMessageByDate"
export const StudentGetMesssageDateList = "/api/Student/GetMessageDateList"
export const SendMessageToStudent = "/api/Student/SendMessageToStudent"
export const StudentDetail = "/api/Student/Details"
export const ListStudent = "/api/Student/Search"
export const CreateFolder = "/api/Student/CreateFolder"
export const SearchFolderInFolder = "/api/Student/SearchFolderInFolder"
export const SaveNote = "/api/Student/SaveNote"
export const SearchNoteInFolder = "/api/Student/SearchNoteInFolder"
export const GetFolderDetail = "/api/Student/GetFolderDetail"
export const RenameFolder = "/api/Student/RenameFolder"
export const DeleteFolder = "/api/Student/DeleteFolder"
export const DeleteNote = "/api/Student/DeleteNote"
export const MoveNote = "/api/Student/MoveNote"
export const GetNoteOfWorksheet = "/api/Student/GetNoteOfWorkSheet"
export const DrawNote = "/api/Student/DrawNote"
export const GetNoteDetail = "/api/Student/GetNoteDetail"

// Assignment
export const GetAssignmentDateList = "/api/Assignment/GetAssignmentDateList"
export const AssignmentSearch = "/api/Assignment/Search"
export const GetListWorksheetByBook = "/api/Assignment/GetListWorksheetByBook"
export const GetWorksheetByAssignmentAndIndex = "/api/Assignment/GetWorksheetByAssignmentAndIndex"
export const UpdateAssignment = "/api/Assignment/UpdateAssignment"
export const AssignmentDelete = "/api/Assignment/Delete"
export const UploadImage = "/api/Assignment/UploadImage"
export const CleanAssignment = "/api/Assignment/CleanAssignment"

//AccountRestriction
export const CheckCreateTeacherRestriction = "/api/AccountRestriction/CheckCreateTeacherRestriction"
export const CheckCreateStudentRestriction = "/api/AccountRestriction/CheckCreateStudentRestriction"
export const CheckAssignTeacherRestriction = "/api/AccountRestriction/CheckAssignTeacherRestriction"
export const CheckTeacherUploadDocumentRestriction = "/api/AccountRestriction/CheckTeacherUploadDocumentRestriction"
export const CheckStudentUploadDocumentRestriction = "/api/AccountRestriction/CheckStudentUploadDocumentRestriction"
export const CheckOperatorUploadDocumentRestriction = "/api/AccountRestriction/CheckOperatorUploadDocumentRestriction"
export const GetCurrentAndLimitStudentAccount = "/api/AccountRestriction/GetCurrentAndLimitStudentAccount"
export const GetDayLeftToRestriction = "/api/AccountRestriction/GetDayLeftToRestriction"
export const GetFirstStudentAccount = "/api/AccountRestriction/GetFirstStudentAccount"
export const GetFirstClassOfOperator = "/api/AccountRestriction/GetFirstClassOfOperator"
export const GetFirstTeacherAccount = "/api/AccountRestriction/GetFirstTeacherAccount"
export const GetFirstDocumentOfAccount = "/api/AccountRestriction/GetFirstDocumentOfAccount"
export const GetFirstDocumentOfStudentAndClass = "/api/AccountRestriction/GetFirstDocumentOfStudentAndClass"

export const SearchNotJoinClass = "/api/Class/SearchNotJoinClass"

export const MergeImagesByWidth = "/api/Util/MergeImagesByWidth"

export const GetFolderTree = "/api/Student/GetFolderTree"
export const CheckPermission = "/api/Operator/CheckPermission"

// CHAT
export const GetListChatRoom = "/api/Chat/GetListChatRoom"
export const InsertMessage = "/api/Chat/InsertMessage"
export const GetChatMessages = "/api/Chat/GetChatMessages"
export const GetChatMessagesInGroup = "/api/Chat/GetChatMessagesInGroup"
export const InsertNotification = "/api/Chat/InsertNotification"
export const GetListNotification = "/api/Chat/GetListNotification"
export const DeleteNotification = "/api/Chat/DeleteNotification"
export const DeleteMessage = "/api/Chat/DeleteMessage"
export const SeenMessage = "/api/Chat/SeenMessage"




