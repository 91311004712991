import React, { useState } from "react"
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'

import ClientListing from "./Client/ClientListing"
import Vendor_ChangePassword from "./Account/Vendor_ChangePassword"
import CreateClient from "./Client/CreateClient"
import UpdateClient from "./Client/UpdateClient"
import Payment from "./Payment/index"
import Layout from "../OperatorAdmin/Main"
import * as Fc from '../../utils/Functions'
import * as Const from "../../constants/Const";

function VendorRoute() {
  let matchUrl = useRouteMatch()
  let token = useSelector(state => state.ws.token)
  let role = useSelector(state => state.ws.role)
  const [openPopup, setOpenPopup] = useState(false)

  // if (Fc.getToken() == null)
  if (token == null || token === '' || Fc.getToken() == null)
    return (
      <Redirect to='/' />
    )
  else
    return (
      // Fc.getRole().includes(Const.Role.VENDOR) &&
      role.includes(Const.Role.VENDOR) &&
      <Switch>
        <Route
          path={`${matchUrl.path}/ChangePassword`}
          component={Vendor_ChangePassword}
        />
        <Route path={`${matchUrl.path}/CreateClient`} component={CreateClient} />
        <Route path={`${matchUrl.path}/Client/:id`} component={UpdateClient} />
        <Route path={`${matchUrl.path}/Payment`} exact component={Payment} />

        <Route path={`${matchUrl.path}/ManageClient/:id`} component={Layout} />
        {/* <Route path={`${matchUrl.path}/ManageClient`} component={Layout} />
      <Route path={`${matchUrl.path}/Payment`} exact component={Layout} /> */}

        <Route
          path={`${matchUrl.path}/:type/:keyword`}
          component={ClientListing}
        />
        <Route path={`${matchUrl.path}/:type`} component={ClientListing} />
        <Route path={`${matchUrl.path}`} exact component={ClientListing} />
      </Switch>
    )
}

export default VendorRoute
