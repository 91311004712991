import React, { useRef } from "react"
import { Link, Redirect, Route, useHistory } from "react-router-dom"
import * as Const from "../../../constants/Const"
import ChangePassword from "../../Login/_ChangePassword"
import API from "../../../utils/Api";

//screens
import Banner from '../Banner'
import Browse from "../Browse"
import SectionQuizTest from "../Browse/SearchSectionQuizTest"

import Workbook from "../Workbook"
import Lession from "../Workbook/Lession"
import * as Type from './../../../constants/actionType'
import * as Fc from './../../../utils/Functions'
import LayoutSearch from "./LayoutSearch"

//component
import ButtonBack from '../../../components/ButtonGoBack'
import Loading from '../../../components/Loading'
import Drawer from '../../../components/HeaderIpad/Drawer'

import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  isDesktop,
  isTablet, isIPad13,
  isMobile
} from "react-device-detect";
import HeaderDesktop from '../../../components/HeaderDesktop/HeaderDesktop'

function LayoutComponent(props) {
  let dispatch = useDispatch()
  const {
    hideMenu,
    showMenu,
    matchUrl,
    logOut,
    operatorInfo,
    role,
    openPopup,
    setOpenPopup,
    operatorId,
    socket,
    message,
    setMessage,
    messageList,
    setMessageList,
    listClass,
    ClassId,
    setClassId,
    ListStudent,
    ListTeacher,
    setSelectedId,
    selectedId, room,
    tabChat,
    setTabChat,
    userType, setUserType,
    userName, setUserName,
    getListNotif,
    scrollToBottom,
    messageEndRef,
    setUserSendMessagge,
    getListMessageInGroup,
    GetChatMessages,
    getDetailClass,
    setPageIndex,
    onScrollTop,
    pageIndex
  } = props

  let history = useHistory();
  let user = useSelector(state => state.ws.userLogin)
  let countNotif = useSelector(state => state.ws.countNotif)
  let searchParams = useSelector(state => state.ws.params)

  const sendMessage = async () => {
    if (message === "") {
      return
    }

    let messageContent = {
      room: room,
      Token: Fc.getToken(),
      content: {
        From: Fc.getRole() === Const.Role.VENDOR ? Const.Role.VENDOR : Fc.getUserId(),
        To: (selectedId === "" || selectedId === "Teacher/Student" ) ? room : selectedId,
        Type: (selectedId !== "" && selectedId !== "Teacher/Student") ? "normal" : "groupchat",
        Body: message,
        FromRole: Fc.getRole(),
        ToRole: (selectedId !== "" && selectedId !== "Teacher/Student") ? userType : "CLASS",
        FromName: user.FirstName + " " + user.LastName,
        ToName: (selectedId !== "" && selectedId !== "Teacher/Student") ? userName : ClassId,
        ClassId: ClassId,
        CreatedDate: new Date()
      },
    };

    await socket.emit("send_message", messageContent);
    setMessageList([...messageList, messageContent.content]);
    setMessage("");
  };

  function onHideIpad() {
    hideMenu();
    // dispatch({ type: Type.SELECTWS, payload: [] })
    dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: null });
  }

  if (Fc.getToken() === null) {
    return (<Redirect to="/" />)
  } else
    return (
      <>
        <Loading />
        <b className="screen-overlay"></b>

        {/*====== Drawer ipad======*/}
        <Drawer
          role={role}
          hideMenu={onHideIpad}
          matchUrl={matchUrl}
          operatorInfo={operatorInfo}
          logOut={logOut}
        />
        {/*====== End Drawer ipad======*/}

        {/*====== Modal Search ======*/}
        <div className="modal fade" id="myModal">
          <LayoutSearch matchUrl={matchUrl} {...props} />
        </div>
        {/*====== End Modal search ======*/}

        {/*====== Header Desktop ======*/}
        { (isDesktop && !isMobile)
          && ( <HeaderDesktop
              role={role}
              hideMenu={hideMenu}
              matchUrl={matchUrl}
              operatorInfo={operatorInfo}
              logOut={logOut}
            />)
        }
        {/*======End Header Desktop ======*/}

        {/*====== Header Ipad ======*/}
        {   (isTablet || isMobile || isIPad13)
          && <nav className="header fixed-top p-3 z-5 drop-shadow">
            <div className="row align-items-center">
              <div className="col-auto">
                <Link to="#" 
                  onClick={showMenu}
                  // data-trigger="#my_offcanvas1"
                  >
                  <i className="fas fa-bars fa-2x"></i>
                </Link>
              </div>

              <div className="mx-auto">
                <h3 className="font-Paytone">
                  {
                    !(operatorInfo.BusinessName || operatorInfo.FullName)
                      ? 'Epen'
                      : (operatorInfo.BusinessName || operatorInfo.FullName)
                  }
                </h3>
              </div>

              <div className="col-auto" >
                {
                  matchUrl.isExact
                    ? (
                      <Link className="" to="#" data-toggle="modal" data-target="#myModal">
                        <i className="fas fa-search fa-2x"></i>
                      </Link>
                    )
                    : (<>
                      <Route path={`${matchUrl.path}/Search`} exact>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu();
                            history.push(`${matchUrl.url}`);
                            dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: null });
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Search?keyword=:keyword`}>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu();
                            history.push(`${matchUrl.url}`);
                            // dispatch({ type: Type.SAVE_PARAMS_SEARCH, payload: null });
                          }}
                        />
                      </Route>


                      <Route path={`${matchUrl.path}/ChangePassword`}>
                        <ButtonBack
                          handleClick={() => { hideMenu(); history.push(`${matchUrl.url}`); }}
                        />
                      </Route>


                      <Route path={`${matchUrl.path}/Workbook/:workbookId`} exact>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu();
                            // dispatch({ type: Type.SELECTWS, payload: [] })
                            props.history.push(`${matchUrl.url}`);
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Workbook/:workbookId/Lession/:lessionId`}>
                        <ButtonBack
                          handleClick={() => {
                            hideMenu()
                            if (!searchParams) {
                              let url = window.location.href
                              let eIndex = url.toLowerCase().indexOf("/lession/")

                              url = url.substring(0, eIndex)
                              let sIndex = url.toLowerCase().indexOf("/workbook/")

                              url = url.substring(sIndex)
                              props.history.push(`${matchUrl.url}${url}`)
                              return
                            }

                            if (matchUrl.params.id) {
                              if (role === Const.Role.VENDOR) {
                                props.history.push(`/Vendor/ManageClient/${operatorId}/${searchParams}`)
                              }
                            } else if (role === Const.Role.OPERATOR) {
                              props.history.push(`/operator/${searchParams}`)
                            } else {
                              props.history.push(`/Teacher/${searchParams}`)
                            }
                          }}
                        />
                      </Route>

                      <Route path={`${matchUrl.path}/Banner`}>
                        <ButtonBack
                          handleClick={() => { hideMenu(); history.push(`${matchUrl.url}`); }}
                        />
                      </Route>
                    </>)
                }
              </div>
            </div>
          </nav>
        }
        {/*====== End Header Ipad ======*/}

        <main>
          <Route path={`${matchUrl.path}`} exact component={Browse} />
          <Route path={`${matchUrl.path}/Search`} exact component={SectionQuizTest} />
          <Route path={`${matchUrl.path}/Search?keyword=:keyword`} component={SectionQuizTest} />
          <Route path={`${matchUrl.path}/ChangePassword`}>
            <div className="bg-xanh"></div>
            {/* <div className="row">
              <div className="col-6 offset-3" style={{top: "150px"}}>
                <div className="row align-items-center">
                  <div className="mx-auto">
                    <h3 className="font-Paytone">Change Password</h3>
                  </div>
                </div>
              </div>
            </div> */}
            <ChangePassword {...props} />
          </Route>

          <Route path={`${matchUrl.path}/Workbook/:workbookId`} exact component={Workbook} />
          <Route path={`${matchUrl.path}/Workbook/:workbookId/Lession/:lessionId`} component={Lession} />
          <Route path={`${matchUrl.path}/Banner`} component={Banner} />


          {/* ====== */}
        </main>
      </>
    )
}

export default React.memo(LayoutComponent)
