/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ChangeEventHandler } from 'react'
import jsPDF from "jspdf";
import * as Const from '../../constants/Const'
import API from '../../utils/Api'
import * as Fc from '../../utils/Functions'
import SendWorksheetsView from './SendWorksheetsView'
import { useRouteMatch } from 'react-router-dom'
import * as _ from "lodash"
import * as $ from 'jquery'

import { useDispatch, useSelector } from 'react-redux'
import * as type from "./../../constants/actionType"

function SendWorksheets(props) {
    const dispatch = useDispatch();

    const role = Fc.getRole()
    const matchUrl = useRouteMatch()

    const { workbookId } = matchUrl.params

    const operatorId = Fc.getRole() === Const.Role.VENDOR ? props.match.params.id : Fc.getOperator()
    const method = Fc.getAccountMethod()
    const [listWs, setListWs] = useState(props.listWorksheets)
    const [zoomWorksheet, setZoomWorksheet] = useState({})
    const [zoomImages, setZoomImages] = useState([])
    const [zoomIndex, setZoomIndex] = useState(0)

    const [showZoomInstruct, setShowZoomInstruct] = useState(true)
    const [showSelected, setShowSelected] = useState(null)
    const [showZoomModal, setShowZoomModal] = useState(false)
    const [showAssignModal, setShowAssignModal] = useState(false)
    const [banner, setBanner] = useState('')
    const [imageRatio, setImageRatio] = useState(1.5)
    const staticBanner = window.bannerImage

    const [classOptions, setClassOptions] = useState([])
    const [classId, setClassId] = useState('')
    const [listStudents, setListStudents] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    const [showSolution, setShowSolution] = useState(false)
    const [listSolutions, setListSolutions] = useState([])
    const [isShowArrowBtnWs, setIsShowArrowBtnWs] = useState(false)

    // if true, sending API update is going on, can not send another
    const [onSendingAPI, setOnSendingAPI] = useState(false)

    // Modal alert Update
    const [alertMessage, setAlertMessage] = useState("")
    const [isShowAssignAlertModal, setShowAssignAlertModal] = useState(false)
    const [userName, setUsername] = useState(null)


    let listWbFromRedux = useSelector(state => state.ws.selectWs)
    let permisSolution = useSelector(state => state.ws.permissionSolution)
    let searchParams = useSelector(state => state.ws.params)
    let currentChapter = useSelector(state => state.ws.currentChapter)
    let currentSession = useSelector(state => state.ws.currentSession)

    useEffect(() => {
        getClassList()
        getBanner()
        getAccount()
        let staticBanner = new Image()
        staticBanner.onload = function () {
            setImageRatio(this.width / this.height)
        }
        staticBanner.src = listWs[0].Url
    }, [])

    function ShowAssignAlert() {
        setShowAssignAlertModal(true)
    }
    function onHideAssignAlert() {
        setShowAssignAlertModal(false)
    }

    $( ".focus" ).click(function() {
        $(".focus").removeClass("this-select");
        $(this).addClass( "this-select" );
      });

    function getBanner() {
        let id = ""
        if (role === "VENDOR") {
            id = props.match.params.id
        } else if (role === "OPERATOR" || role === "TEACHER") {
            id = sessionStorage.getItem('operator')
        }
        API.getBannerByOperator(id)
            .then((res) => {
                if (res.status === 200 && res.data.Success) {
                    setBanner(res.data.Data);
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
            .catch(err => {
                Fc.showError(err)
            })
    }
    useEffect(() => {
        setListWs(props.listWorksheets)
    }, [props.listWorksheets])

    useEffect(() => {
        if (zoomWorksheet) {
            let images = [zoomWorksheet.Url]
            if (zoomWorksheet.Workbook && zoomWorksheet.Solutions.length > 0) {
                zoomWorksheet.Solutions.map((item, index) => {
                    images.push(item.Url)
                })
            }
            setZoomImages(images)
        }
    }, [zoomWorksheet])

    useEffect(() => {
        if (showZoomModal) {
            let modal = document.getElementById("zoomModal")
            modal.classList.add("modal-dialog-centered")
            modal.classList.add("mt-0")
            modal.classList.add("mb-0")
        }
    }, [showZoomModal])

    useEffect(() => {
        if (classId)
            getStudentsOfClass()
        else
            setListStudents([])
    }, [classId])

    useEffect(() => {
        if (listWs[0].Workbook) {
            let solutions = []
            listWs.filter(x => x.Checked).map((item, index) => {
                solutions = solutions.concat(item.Solutions)
            })
            setListSolutions(solutions)
        }
    }, [listWs])

    useEffect(() => {
        if (listWbFromRedux && listWbFromRedux.some(a => a.workbookId === workbookId) === false) {
            dispatch({ type: type.SELECTWS, payload: [] })
        }
    }, [])

    useEffect(() => {
        let timer = setTimeout(() =>{
            if(listWbFromRedux && listWbFromRedux.length > 0){
                const worksheetScrollWth = $('#scroll-worksheet').width();
                const lastWsOffsetLeft = $(".el-worksheet").last() && $(".el-worksheet").last().offset() ? $(".el-worksheet").last().offset().left : 0;
                const lastWsWth = $(".el-worksheet").last() && $(".el-worksheet").last().width() ? $(".el-worksheet").last().width() : 0;
    
                if((lastWsOffsetLeft + lastWsWth) > worksheetScrollWth) {
                    setIsShowArrowBtnWs(true)
                } else {
                    setIsShowArrowBtnWs(false)
                }
                return 
            }
            setIsShowArrowBtnWs(false)
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [listWbFromRedux, showSolution])

    function selectWorksheet(id) {
        setShowSelected(true)

        let worksheets = Fc.deepCopy(listWs)
        let ws = worksheets.filter(x => x._id === id)[0]
        ws.Checked = ws.Checked ? !ws.Checked : true
        setListWs(worksheets)

        let listSelectedWb = _.cloneDeep(listWbFromRedux)
        if (listSelectedWb && listSelectedWb.length > 0) {
            if (listSelectedWb[0].worksheet.some(x => x.worksheetId === id)) {
                if (listSelectedWb[0].worksheet.map(x => x.worksheetId).includes(id)) {
                    ws.Checked = false;
                    listSelectedWb[0].worksheet = listSelectedWb[0].worksheet.filter(x => x.worksheetId !== id)
                }
            }
        }
        // if check worksheet
        if (ws.Checked) {
            setZoomWorksheet(ws)
            if (!listSelectedWb)
                listSelectedWb = []
            if (listSelectedWb && listSelectedWb.filter(x => x.workbookId === workbookId).length === 0) {
                let Category = ws.Type === "Document" ? "" : ws.Workbook.Category
                // let Solutions = ws.Type === "Document" ? "" : (ws.Workbook.Category === "Concept" ? "" : ws.Solutions[0].Url) do ko hien solution của concept
                let Solutions = ws.Type === "Document" ? "" : (ws.Solutions.length > 0 ? ws.Solutions[0].Url : "")
                listSelectedWb.push({ workbookId: workbookId, worksheet: [{ worksheetId: id, url: ws.Url, Solutions: Solutions, Category: Category, Chapter: currentChapter , Session: currentSession }] })
            } else {
                let wb = listSelectedWb.filter(x => x.workbookId === workbookId)[0]
                let Category = ws.Type === "Document" ? "" : ws.Workbook.Category
                // let Solutions = ws.Type === "Document" ? "" : (ws.Workbook.Category === "Concept" ? "" : ws.Solutions[0].Url) thang nay giong thăng tren
                let Solutions = ws.Type === "Document" ? "" : (ws.Solutions.length > 0 ? ws.Solutions[0].Url : "")
                wb.worksheet.push({ worksheetId: id, url: ws.Url, Solutions: Solutions, Category: Category, Chapter: currentChapter, Session: currentSession })
            }
        }
        //if uncheck worksheet and this worksheet is zoomed
        else if (zoomWorksheet._id === ws._id) {
            if (listSelectedWb && listSelectedWb.filter(x => x.workbookId === workbookId).length > 0) {
                let wb = listSelectedWb.filter(x => x.workbookId === workbookId)[0]
                wb.worksheet = wb.worksheet.filter(x => x.worksheetId !== id)
            }
            if (listSelectedWb.length > 0) {
                setZoomWorksheet(listSelectedWb[listSelectedWb.length - 1])
            }
            else
                setZoomWorksheet({})
        }
        dispatch({ type: type.SELECTWS, payload: listSelectedWb })
        if (showZoomInstruct) {
            setTimeout(() => setShowZoomInstruct(false), 2000)
        }

    }

    function selectAllWorksheet() {
        setShowSelected(true)

        let worksheets = Fc.deepCopy(listWs)
        setZoomWorksheet(worksheets[worksheets.length - 1])
        worksheets.map(item => {
            item.Checked = true
        })
        setListWs(worksheets)

        let listSelectedWb = _.cloneDeep(listWbFromRedux)
        let ws = worksheets.filter(x => x.Checked)

        if (!listSelectedWb)
            listSelectedWb = []
        if (listSelectedWb.filter(x => x.workbookId === workbookId).length === 0) {
            listSelectedWb = [{ workbookId, worksheet: [] }]

            ws.forEach(item => {
                let Category = item.Type === "Document" ? "" : item.Workbook.Category
                let Solutions = item.Type === "Document" ? "" : (item.Solutions.length > 0 ? (item.Solutions[0] && item.Solutions[0].Url ? item.Solutions[0].Url : "") :  "");
                if (listSelectedWb.some(x => x.workbookId === workbookId)) {
                    listSelectedWb.map(x => {
                        x.worksheet.push({ worksheetId: item._id, url: item.Url, Solutions: Solutions, Category: Category, Chapter: currentChapter, Session: currentSession })
                    })
                };
            });
        }
        else {
            let arrID = listSelectedWb[0].worksheet.map(ws => ws.worksheetId)
            const arr = ws.filter(obj => !arrID.includes(obj._id))
            arr.forEach(item => {
                let Category = item.Type === "Document" ? "" : item.Workbook.Category
                let Solutions = item.Type === "Document" ? "" : (item.Solutions.length > 0 ? (item.Solutions[0] && item.Solutions[0].Url ? item.Solutions[0].Url : "") :  "") 
                if (listSelectedWb.some(x => x.workbookId === workbookId)) {
                    listSelectedWb.map(x => {
                        x.worksheet.push({ worksheetId: item._id, url: item.Url, Solutions: Solutions, Category: Category, Chapter: currentChapter, Session: currentSession })
                    })
                };
            });
        }
        dispatch({ type: type.SELECTWS, payload: listSelectedWb })
        if (showZoomInstruct) {
            setTimeout(() => setShowZoomInstruct(false), 2000)
        }
    }

    function clearAllWorksheet() {
        let worksheets = Fc.deepCopy(listWs)
        let listSelectedWb = _.cloneDeep(listWbFromRedux)
        setShowSelected(false)

        //====== Logic cua Tam chi xoa page worksheet o 1 session ==========
        // worksheets.forEach(item => {
        //     if (listSelectedWb.some(x => x.workbookId === workbookId)) {
        //         if (listSelectedWb[0].worksheet.map(x => x.worksheetId).includes(item._id)) {
        //             item.checked = false;
        //             listSelectedWb[0].worksheet = listSelectedWb[0].worksheet.filter(x => x.worksheetId !== item._id)
        //         }
        //     }
        // });

        // dispatch({ type: type.SELECTWS, payload: listSelectedWb })
        //====== End Logic cua Tam chi xoa page worksheet o 1 session ======

        dispatch({ type: type.SELECTWS, payload: [] })

        setZoomWorksheet({})
        worksheets.map(item => {
            item.Checked = false
        })
        setListWs(worksheets)
    }

    function hideZoomModal() {
        setShowZoomModal(false)
        setZoomIndex(0)
    }

    function onSwipeLeft() {
        if (permisSolution === false) {
            return
        }
        if (zoomIndex < zoomImages.length - 1) {
            setZoomIndex(zoomIndex + 1)
        }
    }

    function onSwipeRight() {
        if (zoomIndex > 0) {
            setZoomIndex(zoomIndex - 1)
        }
    }

    function hideAssignModal() {
        setShowAssignModal(false)
    }

    function getClassList(keyword) {
        if (Fc.getRole() === Const.Role.TEACHER) {
            API.getClassByPrimaryTeacher().then(res => {
                if (res.status === 200 && res.data.Success) {
                    if (res.data.Data) {

                        let classes = res.data.Data
                        setClassOptions(classes)
                        setClassId(classes.length ? classes[0]._id : null)
                    }
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
        } else {
            API.listClass(operatorId, 1, 20, keyword, true).then(res => {
                if (res.status === 200 && res.data.Success) {
                    let data = res.data.Data
                    data.map(item => {
                        item.value = item._id
                        item.label = item.Code
                    })
                    setClassOptions(data)
                } else {
                    Fc.showError(res.data.Message || res.statusText)
                }
            })
        }
    }

    function getStudentsOfClass() {
        API.detailClass(classId).then(res => {
            if (res.status === 200 && res.data.Success) {
                let data = res.data.Data
                setListStudents(data.Students)
            } else {
                Fc.showError(res.data.Message || res.statusText)
            }
        })
    }

    function selectStudent(id) {
        let students = Fc.deepCopy(listStudents)
        let student = students.filter(x => x._id === id)[0]
        student.Checked = student.Checked ? !student.Checked : true
        setListStudents(students)
        setSelectAll(false)
    }

    function selectAllStudent(checked) {
        let students = Fc.deepCopy(listStudents)
        students.map(item => {
            item.Checked = checked
        })
        setListStudents(students)
        setSelectAll(checked)
    }

    

    async function generatePdfFromImages(worksheets, includeSolution) {
        var completeCount = 0;
        var pdf = new jsPDF('p', 'in', 'letter');
        pdf.deletePage(1)
        var imgUrl = new Array();
        if(includeSolution){
            for (var i=0; i< worksheets.length; i++){
                imgUrl[i] = worksheets[i].url.replace('duvvc7imo9p1x.cloudfront.net', 'epenimport.s3.ca-central-1.amazonaws.com');
            }
            for (var i=0; i< worksheets.length; i++){
                imgUrl[i+worksheets.length] = worksheets[i].Solutions.replace('duvvc7imo9p1x.cloudfront.net', 'epenimport.s3.ca-central-1.amazonaws.com');
            }
        }else{
            for (var i=0; i< worksheets.length; i++){
                imgUrl[i] = worksheets[i].url.replace('duvvc7imo9p1x.cloudfront.net', 'epenimport.s3.ca-central-1.amazonaws.com');
            }
        }
        var cat = worksheets[0].Category
        if (cat === 'Foundation') {
            var dynamicBannerUrl = banner.replace('duvvc7imo9p1x.cloudfront.net', 'epenimport.s3.ca-central-1.amazonaws.com');
            var dynamicBanner = await addImgProcess(dynamicBannerUrl);

            var UniversalBannerUrl = '/static-banner.jpg';
            var UniversalBanner = await addImgProcess(UniversalBannerUrl);
        }
        for(const element of imgUrl) {
                    var imgData = await getBase64Image(element, cat, dynamicBanner, UniversalBanner);
                    pdf.addPage();
                    pdf.addImage(imgData, 0, 0, 8.5, 11);
                    completeCount = completeCount + 1;
                    var output = pdf.output('bloburl')
                    if (completeCount === imgUrl.length) {
                        window.open(output, '_blank');
                    }
            }
        }
    
    async function addImgProcess(url) {
 
        var value = new Promise((resolve, reject) => {
            var img = new Image();          
            img.onload = async function() {
                resolve(img)
            };
            img.crossOrigin = 'Anonymous';
            img.onerror = reject;
            img.src = url;
        })
        return value;
   }
    
    async function getBase64Image(url, cat, dynamicBanner, UniversalBanner) {
            var mainWs = await addImgProcess(url);
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            
            canvas.width = mainWs.width;
            canvas.height = mainWs.height;
            
            await ctx.drawImage(mainWs, 0, 0);
            if(cat === 'Foundation') {
                var StaticRatios = UniversalBanner.width/UniversalBanner.height;
                var dynamicRatios = dynamicBanner.width/dynamicBanner.height;

                await ctx.drawImage(UniversalBanner, canvas.width / 2 - canvas.width*0.88 / 2,
                                            canvas.height*0.05,
                                            canvas.width*0.88, 
                                            canvas.width*0.88/StaticRatios);
                await ctx.drawImage(dynamicBanner, canvas.width / 2 - canvas.width*0.4 / 2,
                                            canvas.height*0.118,
                                            canvas.width*0.64, 
                                            canvas.width*0.64/dynamicRatios);
            } 
            var dataURL = canvas.toDataURL("image/jpeg");
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
    function goBack() {
        //clear selected worksheet, current chapter, current session on desktop browser
        // dispatch({ type: type.SELECTWS, payload: [] })
        dispatch({ type: type.SAVE_CURRENT_CHAPTER, payload: null })
        dispatch({ type: type.SAVE_CURRENT_SESSION, payload: null })

        if(!searchParams) {
            if (matchUrl.params.workbookId || matchUrl.params.id) {
                if (role === Const.Role.VENDOR) {
                    props.history.push(`/Vendor/ManageClient/${operatorId}`)
                } else if (role === Const.Role.OPERATOR) {
                    props.history.push(`/operator`)
                } else {
                    props.history.push(`/Teacher`)
                }
            }
            if(matchUrl.params.documentId){
                if (role === Const.Role.VENDOR) {
                    props.history.push(`/Vendor/ManageClient/${operatorId}/Upload`)
                } else if (role === Const.Role.OPERATOR) {
                    props.history.push(`/operator/Upload`)
                } else {
                    props.history.push(`/Teacher/Upload`)
                }
            }
            return  
        } 

        if (matchUrl.params.workbookId || matchUrl.params.id) {
            if (role === Const.Role.VENDOR) {
                props.history.push(`/Vendor/ManageClient/${operatorId}/${searchParams}`)
            } else if (role === Const.Role.OPERATOR) {
                props.history.push(`/operator/${searchParams}`)
            } else {
                props.history.push(`/Teacher/${searchParams}`)
            }
        } 
    }

    function getAccount() {
        API.getAccountInfo().then(res => {
            if (res.status === 200 && res.data.Success) {
                if (Fc.getRole() === Const.Role.VENDOR) {
                    //vendor show email 
                    setUsername(res.data.Data.Email)
                } else if (Fc.getRole() === Const.Role.OPERATOR) {
                    //operator show account id
                    setUsername(res.data.Data.Email)
                } else {
                    setUsername(res.data.Data.SSOAccountId)
                }
            } else {
                if (res.data.Message)
                    Fc.showError(res.data.Message)
                else
                    Fc.showError(res.statusText)
            }
        }).catch(err => {
        })
    }

    function onClickRightWs() {
        let leftPos = $('#scroll-worksheet').scrollLeft();
        $("#scroll-worksheet").animate({scrollLeft: leftPos + 450}, 750);
    }

    function onClickLeftWs() {
        let leftPos = $('#scroll-worksheet').scrollLeft();
        $("#scroll-worksheet").animate({scrollLeft: leftPos - 450}, 750);
    }

    function pushAppBottom(e){
        document.getElementById('tableOfContent').style.marginBottom =  300 +'px';
        document.querySelectorAll('.cus-collapse')[e].classList.toggle('show');
    }
    return (
        <SendWorksheetsView
            listWs={listWs}
            selectWorksheet={selectWorksheet}
            zoomWorksheet={zoomWorksheet} setZoomWorksheet={setZoomWorksheet}
            zoomImages={zoomImages}
            zoomIndex={zoomIndex}
            selectAllWorksheet={selectAllWorksheet}
            clearAllWorksheet={clearAllWorksheet}
            showZoomInstruct={showZoomInstruct}
            showSelected={showSelected} setShowSelected={setShowSelected} pushAppBottom={pushAppBottom}
            showZoomModal={showZoomModal} setShowZoomModal={setShowZoomModal}
            hideZoomModal={hideZoomModal}
            showAssignModal={showAssignModal} setShowAssignModal={setShowAssignModal}
            hideAssignModal={hideAssignModal}
            classOptions={classOptions}
            classId={classId} setClassId={setClassId}
            getClassList={getClassList}
            listStudents={listStudents}
            selectStudent={selectStudent}
            selectAll={selectAll}
            selectAllStudent={selectAllStudent}
            generatePdfFromImages={generatePdfFromImages}
            getBase64Image={getBase64Image}
            showSolution={showSolution} setShowSolution={setShowSolution}
            listSolutions={listSolutions}
            onSwipeLeft={onSwipeLeft} onSwipeRight={onSwipeRight}
            onHideAssignAlert={onHideAssignAlert} alertMessage={alertMessage}
            isShowAssignAlertModal={isShowAssignAlertModal}
            role={role} banner={banner}
            method={method} setAlertMessage={setAlertMessage}
            ShowAssignAlert={ShowAssignAlert}
            setShowAssignAlertModal={setShowAssignAlertModal}
            staticBanner={staticBanner}
            imageRatio={imageRatio}
            listWbFromRedux={listWbFromRedux}
            workbookId={workbookId}
            operatorId={operatorId}
            goBack={goBack}
            userName={userName}
            onClickLeftWs={onClickLeftWs}
            onClickRightWs={onClickRightWs}
            isShowArrowBtnWs={isShowArrowBtnWs}
            {...props} />
    )
}



export default React.memo(SendWorksheets);