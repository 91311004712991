import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import Login from "./screens/Login/Login"
import PasswordRecovery from "./screens/Login/PasswordRecovery"
import ChangePasswordByEmail from "./screens/Login/ChangePasswordByEmail.js"
import VendorRoute from "./screens/VendorAdmin/VendorRoute"
import OperatorRoute from "./screens/OperatorAdmin/Main"
import TeacherRoute from "./screens/Teacher/Main"
import ConfirmSendMail from "./screens/Login/ConfirmSendMail"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//css
import "./App.css"
// import 'react-select/dist/css/react-select.css';

toast.configure()

function App(props) {
  useEffect(() => {
    document.title = "ePen"
  })

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/vendor" component={VendorRoute} />
          <Route path="/operator" component={OperatorRoute} />
          <Route path="/Teacher" component={TeacherRoute} />

          <Route path="/PasswordRecovery" exact component={PasswordRecovery} />
          <Route path="/PasswordRecovery/SendMailSuccess" component={ConfirmSendMail} />
          <Route path="/PasswordRecovery/:tokenId" component={ChangePasswordByEmail} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
