import React from 'react';

function ButtonGoBack(props) {
    const {
        handleClick
    } = props
    if(window.location.href.includes("Classes") && window.innerWidth < 576){
        return (
            <div></div>
        )
    }else{
        return (
            <div onClick={handleClick}>
                <i className="fas fa-arrow-left fa-2x"></i>
            </div>
    )};
}

export default ButtonGoBack;